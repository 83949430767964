import React, { useEffect, useMemo } from 'react';
import { Button, Divider, Grid } from 'semantic-ui-react';
import OFFERED_INSTRUMENTS from '../../../consts/instruments';
import { useSignup } from '../SignupContext.react';
import WindowUtils from '../../Utils/WindowUtils';
import InstrumentChoiceCard from './InstrumentChoiceCard';
import ContainerWithLoader from '../../ContainerWithLoader/ContainerWithLoader';
import InstrumentPreferences from './InstrumentPreferences';

const ChooseAnInstrument = () => {
  const {
    kid: {
      capitalizedFirstName,
      kidSchoolYear,
      enrolmentInstrument,
      waitlistInstruments,
      offerFirstPreference,
      updateKid,
    },
    timetable: {
      timetableId,
      availablePlacesKnown,
      availablePlacesCountsFor,
      placesAvailable,
    },
    page: { httpRequestPending },
  } = useSignup();

  const choiceMade = useMemo(() => (
    !!enrolmentInstrument || !!waitlistInstruments.length
  ), [enrolmentInstrument, waitlistInstruments]);

  useEffect(() => {
    if (choiceMade && placesAvailable) {
      WindowUtils.scrollToAnchor('instrument-choices');
    }
  }, [choiceMade, placesAvailable]);

  const minDetailsProvided = !!(timetableId && kidSchoolYear !== null);

  const handleWaitlistForAllInstruments = () => {
    updateKid({ waitlistInstruments: OFFERED_INSTRUMENTS });
  };

  const somethingAvailableNothingChosen = placesAvailable && !choiceMade;
  const somethingAvailableUnavailableChosen = (
    placesAvailable && !enrolmentInstrument && !!waitlistInstruments.length
  );

  const personalisedReference = capitalizedFirstName || 'your child';

  const noAvailabilityStatement = () => (
    <div>
      <h5>Add {personalisedReference} to the waitlist</h5>
      <p>
        There are no suitable vacant spaces in any bands for your child&rsquo;s
        {' '}
        age group at the moment, but you can join our waitlist.
      </p>
      <p>We constantly review the waitlist and create space whenever we can.</p>
      <p>
        You can improve your chance of getting a space by joining the waitlist today,
        {' '}
        and choosing all the instruments that your child would like to play.
      </p>
      <br />
    </div>
  );

  const sectionDescription = () => {
    if (placesAvailable && choiceMade) return null;
    if (!placesAvailable) return noAvailabilityStatement();

    return <h5>What would {personalisedReference} like to do?</h5>;
  };

  const choice = (instrumentName, type, tabletAndCompWidth = 8) => (
    <Grid.Column
      key={`${instrumentName}-choice-card`}
      mobile={16}
      tablet={tabletAndCompWidth}
      computer={tabletAndCompWidth}
    >
      <InstrumentChoiceCard
        instrumentName={instrumentName}
        type={type}
      />
    </Grid.Column>
  );

  const waitlistWildCardButton = () => (
    <div>
      <h5>What instrument would your child consider playing?</h5>
      <Button primary onClick={handleWaitlistForAllInstruments}>
        Happy to try any instrument
      </Button>
      <Divider horizontal>OR</Divider>
    </div>
  );

  const upsellText = () => (
    <Grid.Column width={16}>
      <h5>
        Would you like {personalisedReference} to
        join the band today on another instrument, whilst waiting for a space on
        {` ${waitlistInstruments[0]}?`}
      </h5>
      <p>
        If {` ${personalisedReference} `} enrols on another instrument now, they&rsquo;ll
        {' '}
        get all the teamwork, confidence and wellbeing
        {' '}
        benefits of Rocksteady right away, and we&rsquo;ll swap
        {` ${personalisedReference} to `}
        {waitlistInstruments[0]}
        , subject to availability.
      </p>
    </Grid.Column>
  );

  const continueText = () => (
    <Grid.Column width={16}>
      <p>
        If {personalisedReference} doesn&rsquo;t want to try another instrument right now,
        please press continue to complete joining the waitlist.
        {' '}
        You can update your instrument preferences any time later via our Parent Portal,
        {' '}
        called &rsquo;Backstage&rsquo;.
      </p>
    </Grid.Column>
  );

  const renderChoices = () => {
    if (!placesAvailable) {
      return OFFERED_INSTRUMENTS.map(instrument => choice(instrument, 'waitlistOnlyOption'));
    }
    if (somethingAvailableNothingChosen) {
      return OFFERED_INSTRUMENTS.map(instrument => (
        choice(instrument, availablePlacesCountsFor(instrument) ? 'availableOption' : 'waitlistOption')
      ));
    }

    if (somethingAvailableUnavailableChosen) {
      return OFFERED_INSTRUMENTS.filter(instrument => availablePlacesCountsFor(instrument))
        .map(instrument => choice(instrument, 'upsellOption', 16));
    }

    return null;
  };

  const choices = () => (
    <div>
      <div className="mb-16">
        {!placesAvailable && waitlistWildCardButton()}
        <Grid stackable columns={2}>
          {placesAvailable && choiceMade && (
            choice(enrolmentInstrument || waitlistInstruments[0], 'selectedChoice', 16)
          )}
          {somethingAvailableUnavailableChosen && (
            upsellText()
          )}
          {renderChoices()}
          {somethingAvailableUnavailableChosen && continueText()}
        </Grid>
      </div>
      {offerFirstPreference && (
        <div className="mb-16">
          <InstrumentPreferences />
        </div>
      )}
    </div>
  );

  const mainSection = () => (
    <ContainerWithLoader loading={httpRequestPending}>
      {sectionDescription()}
      {availablePlacesKnown && choices()}
    </ContainerWithLoader>
  );

  return (
    <div id="instrument-choices">
      <h3>2. Choose an Instrument</h3>
      {!minDetailsProvided && <p>Please fill in your details to see our available places.</p>}
      {minDetailsProvided && mainSection()}
    </div>
  );
};

export default ChooseAnInstrument;
