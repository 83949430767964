import React, { useCallback, useState } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import { PropTypes } from 'prop-types';
import AltContactInput from '../AltContactInput';
import NameInput from '../NameInput';
import PolicyAndTermsConsentFields from '../PolicyAndTermsConsentFields';
import { useSignup } from '../SignupContext.react';
import AddressInput from '../AddressInput';
import SupplementaryKidInputs from '../SupplementaryKidInputs';
import YourBookingSoFar from '../YourBookingSoFar';
import DirectDebitRadioButtons from './DirectDebitRadioButtons';

const EnrolFields = ({
  canEnrol,
  bankAccountInOwnName,
  onlyBankSignatoryRequired,
  onBankAccountInOwnNameChange,
  onOnlyBankSignatoryRequiredChange,
}) => {
  const {
    kid: { concertVideoPermission },
    grownUp: {
      grownUpName,
      grownUpNameValid,
      addressValid,
      updateGrownUp,
      commsMarketing,
      trimAndCleanGrownUpName,
    },
  } = useSignup();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const [tsAndCsAgreed, setTsAndCsAgreed] = useState(false);

  const handleTsAndCsToggle = useCallback(() => {
    setTsAndCsAgreed(prev => !prev);
  }, []);

  const handleNameChange = useCallback((value) => {
    updateGrownUp({ grownUpName: value });
  }, [updateGrownUp]);

  const requiredDataCollected = (
    grownUpNameValid
      && addressValid
      && concertVideoPermission !== null
      && commsMarketing !== null
      && bankAccountInOwnName !== null
      && onlyBankSignatoryRequired !== null
      && tsAndCsAgreed
  );

  const primaryButtonText = () => {
    if (canEnrol === false) {
      // user has selected options that prevent them from being able to enrol
      return 'Join waitlist';
    }
    // If user has selected valid options, or has not yet selected any options
    // then we should default to the most common text
    return 'Continue to payment';
  };

  return (
    <Grid stackable columns={3}>
      <Grid.Column mobile={16} tablet={16} computer={5}>
        <YourBookingSoFar />
      </Grid.Column>

      <Grid.Column mobile={16} tablet={10} computer={5}>
        <NameInput
          value={grownUpName || ''}
          attrName="grownUpName"
          onChange={handleNameChange}
          isValid={grownUpNameValid}
          label="Your full name"
          onBlur={trimAndCleanGrownUpName}
        />
        <AltContactInput />
        <AddressInput />
        <SupplementaryKidInputs />
      </Grid.Column>

      <Grid.Column mobile={16} tablet={6} computer={6}>
        <DirectDebitRadioButtons
          accountInOwnName={bankAccountInOwnName}
          onAccountInOwnNameChange={onBankAccountInOwnNameChange}
          onlySignatoryRequired={onlyBankSignatoryRequired}
          onOnlySignatatoryRequiredChange={onOnlyBankSignatoryRequiredChange}
        />
        <PolicyAndTermsConsentFields
          tsAndCsAgreed={tsAndCsAgreed}
          onTsAndCsChange={handleTsAndCsToggle}
        />
        <Form.Button
          fluid={isMobile}
          floated="right"
          disabled={!requiredDataCollected}
          primary
          content={primaryButtonText()}
          className="primary-form-button"
        />
      </Grid.Column>
    </Grid>
  );
};

export default EnrolFields;

EnrolFields.propTypes = {
  canEnrol: PropTypes.bool.isRequired,
  bankAccountInOwnName: PropTypes.bool,
  onBankAccountInOwnNameChange: PropTypes.func.isRequired,
  onlyBankSignatoryRequired: PropTypes.bool,
  onOnlyBankSignatoryRequiredChange: PropTypes.func.isRequired,
};

EnrolFields.defaultProps = {
  bankAccountInOwnName: null,
  onlyBankSignatoryRequired: null,
};
