
import React, { useEffect } from 'react';
import { Button, Icon, List } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import paths from '../../../consts/paths';
import { useSignup } from '../SignupContext.react';
import DirectDebitSummary from './DirectDebitSummary';
import BackstageLinkSection from './BackstageLinkSection';
import constructOnSignUpObject from './ga';

const ACTION_CONTENT = Object.freeze({
  joinedWaitlist: {
    headingText: 'Added to the Waitist',
    renderContent: () => <BackstageLinkSection />,
  },
  enrolled: {
    headingText: 'Enrolment Complete',
    renderContent: () => <DirectDebitSummary />,
  },
});

const SOCIALS = Object.freeze(['Facebook', 'Twitter', 'Instagram', 'YouTube']);

const Success = () => {
  const {
    loggedInStaff,
    kid: { kidId, kidSchoolYear, enrolmentInstrument },
    timetable: {
      timetableId, timetableName, lessonsAreOn, regularMonthlyCost,
    },
    page: { successAction },
  } = useSignup();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    if (successAction === 'enrolled') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(
        constructOnSignUpObject({
          kidId,
          kidSchoolYear,
          enrolmentInstrument,
          timetableName,
          lessonsAreOn,
          regularMonthlyCost,
        }),
      );
    }
  }, [successAction]);

  const staffLinks = () => (
    <List horizontal={!isMobile}>
      <List.Item>
        <Button
          size="medium"
          href={`/staff/kids/${kidId}/edit`}
          style={{ margin: 8 }}
        >
          Go to Kid Page
        </Button>
      </List.Item>
      <List.Item>
        <Button
          size="medium"
          href={`/staff/timetables/${timetableId}/edit`}
          style={{ margin: 8 }}
        >
          Go to Timetable Page
        </Button>
      </List.Item>
    </List>
  );

  const socialLinks = () => (
    <List horizontal={!isMobile}>
      {SOCIALS.map(name => (
        <List.Item key={name}>
          <Button
            size="medium"
            color={name.toLowerCase()}
            href={paths[name.toUpperCase()]}
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: 8 }}
          >
            <Icon name={name.toLowerCase()} />
            {name}
          </Button>
        </List.Item>
      ))}
    </List>
  );

  return (
    <div>
      <h2>{ACTION_CONTENT[successAction].headingText}</h2>
      {ACTION_CONTENT[successAction].renderContent()}
      {loggedInStaff ? staffLinks() : socialLinks()}
    </div>
  );
};

export default Success;
