export default class StringUtils {
  static capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

  static humanize = (str) => {
    if (str) {
      return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, m => m.toUpperCase());
    }
    return null;
  };

  static titleize = (str) => {
    if (str) {
      return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, m => m.toUpperCase())
        .replace(/\s[a-z]/, m => m.toUpperCase());
    }
    return null;
  };

  static isEmailLike = str => /^([^@\s]+)(@{1})([^@\s.]{2,})(\.{1}[^@\s.]{2,})+$/i.test(str);
  static isBankAccountNumberLike = str => /^\d{8}$/i.test(str);
  static isBankSortCodeLike = str => /^\d{6}$/i.test(str);

  static keepDigitsOnly = (str) => {
    if (typeof str !== 'string') return str;
    return str.replace(/[^\d]/g, '');
  }

  // removes leading and trailing whitespace, as well as amending
  // instances of 2+ whitespaces to a single whitespace
  static trimAndCleanFullName = (str) => {
    if (typeof str !== 'string') return str;
    return str.trim().replace(/\s{2,}/g, ' ');
  };

  static removeAllWhitespace = (str) => {
    if (typeof str !== 'string') return str;
    return str.replace(/\s+/g, '');
  };

  static toLowerCamel = str => (
    str.toLowerCase()
      .replace(/[\s\-_.]./g, chars => chars.toUpperCase())
      .replace(/[\s\-_.]/g, '')
      .replace(/^./, leadingLetter => leadingLetter.toLowerCase())
  );

  static pluralize = (str, count) => (count > 1 ? `${str}s` : str);

  static stringToKey = str =>
    str
      .toString()
      .toLowerCase()
      .replace(/[^\w-]+/g, ''); // Remove all non-word chars

  static schoolYearAsString = (year) => {
    if (['R', '0', 0].includes(year)) {
      return 'R';
    }
    return year;
  };

  static formatTelephone = (phone) => {
    if (!phone) {
      return '-';
    }
    if (phone.charAt(0) === '0') {
      return phone;
    }
    return `0${phone}`;
  };

  static parseQueryString = (string) => {
    const query = {};
    const pairs = (string[0] === '?' ? string.substr(1) : string).split('&');
    for (let i = 0; i < pairs.length; i += 1) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  };

  static toArrayOfInts = (string) => {
    if (!string) {
      return [];
    }
    let text = string;
    // convert new lines to commas
    text = text.replace(/\n/g, ',');
    // remove anything that's not a comma or an int
    text = text.replace(/[^\d,]/g, '');
    let array = text.split(',');
    // remove falsy items
    array = array.filter(Boolean);
    return array;
  };

  static truncate = (string, limit) => {
    if (string.length > limit) {
      return `${string.substring(0, limit)}...`;
    }
    return string;
  };

  static randomString = () => Math.random().toString(36);

  static removePunctuation = value => value.replace(/[^\w ]+/g, '')

  static parseYoutubeLink = (link) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#]*).*/;
    const match = link.match(regExp);

    if (match && match[2].length === 11) {
      return `//www.youtube.com/embed/${match[2]}`;
    }
    return 'error';
  };

  static sanitizeNameForSD = (name) => {
    // Trim special characters
    const safeName = name.replace(/[^a-zA-Z0-9 ]/g, '');

    if (safeName.length < 18) {
      return safeName;
    }

    // Trim long names
    return safeName.substring(0, 17);
  }

  static ordinalNum = (num) => {
    const j = num % 10;
    const k = num % 100;
    if (j === 1 && k !== 11) {
      return `${num}st`;
    }
    if (j === 2 && k !== 12) {
      return `${num}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${num}rd`;
    }
    return `${num}th`;
  };

  static initials = (name, firstLast = true) => {
    const all = name.split(' ').map(w => w[0].toUpperCase()).join('');
    if (all.length === 1) { return all; }

    return firstLast ? `${all[0]}${all[all.length - 1]}` : all;
  }
}
