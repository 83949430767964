import React, { useCallback, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';
import { useSignup } from '../SignupContext.react';
import EnrolFields from './EnrolFields';

const StepThree = ({ onStepComplete }) => {
  const {
    kid: { handleWaitlistJoin, handleCompletePersonalInformation },
    page: { httpRequestPending },
  } = useSignup();

  const [bankAccountInOwnName, setBandAccountInOwnName] = useState(null);
  const [onlyBankSignatoryRequired, setOnlyBankSignatoryRequired] = useState(null);

  const handleAccountInOwnNameChange = useCallback((_e, { value }) => {
    setBandAccountInOwnName(value !== 'no');
  }, []);

  const handleOnlyBankSignatoryRequiredChange = useCallback((_e, { value }) => {
    setOnlyBankSignatoryRequired(value !== 'no');
  }, []);

  const canEnrol = () => {
    if (bankAccountInOwnName === false || onlyBankSignatoryRequired === false) {
      // user has selected an option that will force them to join the waitlist
      return false;
    }
    // if the values are null, user hasn't engaged yet, and the default for most
    // users is that they will be allowed to enrol, so we present the happy path
    // to them until they tell us otherwise.
    return true;
  };

  const submitAction = () => {
    if (canEnrol()) {
      handleCompletePersonalInformation().then(onStepComplete);
    } else {
      handleWaitlistJoin();
    }
  };

  return (
    <Form loading={httpRequestPending} onSubmit={submitAction}>
      <h2>Almost done...</h2>
      <EnrolFields
        canEnrol={canEnrol()}
        bankAccountInOwnName={bankAccountInOwnName}
        onlyBankSignatoryRequired={onlyBankSignatoryRequired}
        onBankAccountInOwnNameChange={handleAccountInOwnNameChange}
        onOnlyBankSignatoryRequiredChange={handleOnlyBankSignatoryRequiredChange}
      />
    </Form>
  );
};

export default StepThree;

StepThree.propTypes = {
  onStepComplete: PropTypes.func.isRequired,
};
