import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import DateUtils from '../Utils/DateUtils';
import StringUtils from '../Utils/StringUtils';
import { useSignup } from './SignupContext.react';

const YourBookingSoFar = () => {
  const {
    schoolYearStartedIn,
    kid: {
      kidId,
      kidName,
      kidSchoolYear,
      firstLessonDate,
      enrolmentInstrument,
      waitlistInstruments,
      justJoiningWaitlist,
    },
    timetable: {
      timetableName,
      regularMonthlyCost,
      lessonsAreOn,
      upcomingConcert,
    },
    grownUp: {
      firstPaymentDate,
      firstPaymentAmount,
      firstPaymentCoversPeriod,
    },
  } = useSignup();

  const goBack = () => window.history.back();

  const title = kidId ? 'Your place is reserved for 20 minutes' : 'Your booking so far';

  const delayedStartConcert = () => (
    <span>
      <br />
      <br />
      Please Note: All bands are now in final rehearsals for concerts.
      <br />
      <br />
      Your child&rsquo;s first lesson will now be after the concert.
      They&rsquo;ll learn a new song with their
      {' '}
      band, ready for their own moment to shine on stage next term!
    </span>
  );

  const firstLessonCell = () => (
    <React.Fragment>
      {firstLessonDate ? DateUtils.formatDate(firstLessonDate) : 'TBC'}
      {upcomingConcert && delayedStartConcert()}
    </React.Fragment>
  );

  const formattedSchoolYear = () => {
    if (kidSchoolYear === -1) {
      return `Starting Reception in Sept. ${schoolYearStartedIn + 1}`;
    }
    return kidSchoolYear === 0 ? 'R' : kidSchoolYear;
  };

  const enrollingAndJoiningWaitlist = !!enrolmentInstrument && !!waitlistInstruments.length;

  const instrumentCell = () => {
    let inner;

    if (enrollingAndJoiningWaitlist) {
      inner = (
        <React.Fragment>
          Enrol on <strong>{enrolmentInstrument}</strong> & join the
          {' '}
          waitlist to swap to <strong>{waitlistInstruments[0]}</strong>
        </React.Fragment>
      );
    } else if (justJoiningWaitlist) {
      inner = `Joining waitlist for ${waitlistInstruments.join(', ')}`;
    } else {
      inner = StringUtils.humanize(enrolmentInstrument);
    }

    return (
      <React.Fragment>
        {inner}
        {' '}
        {!kidId && (
          <span className="instrument-change-button">
            <Button size="mini" primary onClick={goBack}>Change</Button>
          </span>
        )}
      </React.Fragment>
    );
  };

  const allTableRows = () => ([
    ['Child', kidName],
    ['School', timetableName],
    ['Year', formattedSchoolYear()],
    ['Instrument(s)', instrumentCell()],
    ['Regular Monthly Amount', regularMonthlyCost],
    ['Lessons day', lessonsAreOn],
    ['First Lesson Date', firstLessonCell()],
    ['First Payment Date', firstPaymentDate ? DateUtils.formatDate(firstPaymentDate) : 'TBC'],
    ['First Payment Amount', firstPaymentAmount || 'TBC'],
    ['First Payment Covers', firstPaymentCoversPeriod || 'TBC'],
  ]);

  const tableRows = justJoiningWaitlist ? allTableRows().slice(0, 4) : allTableRows();

  return (
    <div>
      <h3>{title}</h3>
      <Table basic="very" unstackable compact striped columns={2}>
        <Table.Body>
          {tableRows.map(([description, value]) => (
            <Table.Row verticalAlign="middle" key={description}>
              <Table.Cell width={6}>{description}</Table.Cell>
              <Table.Cell collapsing={false} width={10}>{value}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default YourBookingSoFar;
