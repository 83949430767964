import React, { useCallback, useEffect, useMemo } from 'react';
import { Message, Select } from 'semantic-ui-react';
import { useSignup } from '../SignupContext.react';
import StringUtils from '../../Utils/StringUtils';
import DateUtils from '../../Utils/DateUtils';

const PRE_RECEPTION_YEAR = -1;
const RECEPTION_YEAR = 0;
const NOMINAL_MIN_SCHOOL_YEAR = PRE_RECEPTION_YEAR;
const NOMINAL_MAX_SCHOOL_YEAR = 13;

const SchoolYearSelect = () => {
  const {
    schoolYearStartedIn,
    kid: {
      kidNameValid,
      kidSchoolYear,
      updateKid,
    },
    timetable: {
      timetableId,
      minSchoolYear,
      maxSchoolYear,
    },
    page: { httpRequestPending },
  } = useSignup();

  const consideredMinSchoolYear = useMemo(() => (
    minSchoolYear || NOMINAL_MIN_SCHOOL_YEAR
  ), [minSchoolYear]);

  const consideredMaxSchoolYear = useMemo(() => (
    maxSchoolYear || NOMINAL_MAX_SCHOOL_YEAR
  ), [maxSchoolYear]);

  const formattedSchoolYearOptions = useMemo(() => {
    const options = [];
    for (let i = consideredMinSchoolYear; i <= consideredMaxSchoolYear; i += 1) {
      let text = `Year ${i}`;
      if (i === PRE_RECEPTION_YEAR) {
        text = `Starts Reception in September ${schoolYearStartedIn + 1}`;
      } else if (i === RECEPTION_YEAR) {
        text = 'Reception';
      }
      options.push({ value: i, text });
    }
    return options;
  }, [consideredMinSchoolYear, consideredMaxSchoolYear]);

  useEffect(() => {
    if (kidSchoolYear !== null) {
      if (kidSchoolYear < consideredMinSchoolYear || kidSchoolYear > consideredMaxSchoolYear) {
        updateKid({ kidSchoolYear: null });
      }
    }
  }, [consideredMinSchoolYear, consideredMaxSchoolYear, kidSchoolYear]);

  const handleSchoolYearChange = useCallback((_e, { value }) => {
    updateKid({ kidSchoolYear: value });
  }, []);

  // Only need to show info message in the months leading up to the end of the school year,
  // as this is where there is most likely to be confusion
  const betweenMarchAndSep = useMemo(() => DateUtils.dateIsBetweenMonths(3, 9), []);

  const schoolYearMessageRequired = !!(
    betweenMarchAndSep && kidSchoolYear !== null && kidSchoolYear >= 0
  );

  const schoolYearInfoMessage = () => (
    <Message>
      <Message.Header>Please check...</Message.Header>
      <Message.List>
        <Message.Item>
          They are currently in Year {StringUtils.schoolYearAsString(kidSchoolYear)}
        </Message.Item>
        <Message.Item>
          In September {schoolYearStartedIn + 1} they
          {' '}
          will be in Year {StringUtils.schoolYearAsString(kidSchoolYear + 1)}
        </Message.Item>
      </Message.List>
    </Message>
  );

  return (
    <div className="field">
      <label>Your child&apos;s school year</label>
      <Select
        data-testid="school-year-select"
        disabled={
          httpRequestPending || ((!kidNameValid || !timetableId) && kidSchoolYear === null)
        }
        compact
        options={formattedSchoolYearOptions}
        placeholder={`in September ${schoolYearStartedIn}`}
        onChange={handleSchoolYearChange}
        value={kidSchoolYear}
        label="Your child's school year"
      />
      {schoolYearMessageRequired && schoolYearInfoMessage()}
    </div>
  );
};

export default SchoolYearSelect;
