import React from 'react';
import { PropTypes } from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Button, Grid, Icon } from 'semantic-ui-react';
import WindowUtils from '../../Utils/WindowUtils';
import CheckLessonTimes from './CheckLessonTimes';
import ChooseAnInstrument from './ChooseAnInstrument';
import { useSignup } from '../SignupContext.react';
import externalUrls from '../../../consts/external_urls';
import FAQModal from './FAQModal';

const StepOne = ({ onStepComplete }) => {
  const {
    kid: {
      kidNameValid,
      enrolmentInstrument,
      waitlistInstruments,
    },
  } = useSignup();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const readyForStepTwo = !!enrolmentInstrument || !!waitlistInstruments.length;

  const handleScrollToNameInput = () => {
    WindowUtils.scrollToAnchor('kid-name-input');
  };

  const continueButton = () => (
    <div className="flex-row justify-end">
      <Button
        fluid={isMobile}
        icon
        primary
        labelPosition="right"
        onClick={kidNameValid ? onStepComplete : handleScrollToNameInput}
        className="primary-form-button"
      >
        Continue
        <Icon name="right arrow" />
      </Button>
    </div>
  );

  return (
    <div>
      <div id="backstage-login-bar" className="flex-row justify-between align-center background-info p-16 wrap mb-24">
        <div>
          If you&apos;ve already enrolled a child, or joined the waiting list, please
          {' '}
          <span><a href={externalUrls.BACKSTAGE} target="_blank" rel="noreferrer">login</a></span>
          {' '}
          to manage your account.
        </div>
        <FAQModal />
      </div>
      <Grid relaxed stackable columns={2} >
        <Grid.Column mobile={16} tablet={16} computer={6}>
          <CheckLessonTimes />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={10}>
          <ChooseAnInstrument />
          {readyForStepTwo && continueButton()}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default StepOne;

StepOne.propTypes = {
  onStepComplete: PropTypes.func.isRequired,
};
